import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("contract.select")}
      {...form.getInputProps("IL_001_contrato")}
    >
      <Radio label={`${t("contract.internship")}.`} value="Convenio de prácticas" />
      <Radio label={`${t("contract.fixedTerm")}.`} value="Contrato o vínculo laboral a plazo fijo (tiene fecha de vencimiento)" />
      <Radio label={`${t("contract.indefinite")}.`} value="Contrato o vínculo laboral a plazo indeterminado o indefinido (no vence)" />
    </RadioSurvey>
  );
};

export default Component;
