import React from "react";
import { useTranslation } from "react-i18next";
import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <MultiRadioTable
      form={form}
      label={t("tolerance.label")}
      options={t("tolerance.options", { returnObjects: true }) as string[]}
      questions={t("tolerance.questions", { returnObjects: true }) as string[]}
      questionIds={[
        "TOL_001_bromasSexistas",
        "TOL_002_bromasSobreIdentidadSexual",
        "TOL_003_bromasSobreAparienciaFisica",
        "TOL_004_invitarASalir",
        "TOL_005_contactarPorRedesSociales",
        "TOL_006_solicitarMaterialSexual",
        "TOL_007_rozamientosIndebidos",
        "TOL_008_tocamientosIndebidos",
        "TOL_009_mirarConConnotacionSexual",
        "TOL_010_invitarASalir",
        "TOL_011_insinuarMejoresCondicionesACambioDeRelaciones",
        "TOL_012_ameazarConSuspenderContrato",
      ]}
    />
  );
};

export default Component;
