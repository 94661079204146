import { MultiRadioTable } from "../../components/MultiRadioTable";
import { useTranslation } from 'react-i18next';

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <MultiRadioTable
      form={form}
      label={t("consequences.label")}
      options={t("yesAndNoOptions", { returnObjects: true }) as string[]}
      questions={t("consequences.questions", { returnObjects: true }) as string[]}
      questionIds={["COS_001", "COS_002", "COS_003", "COS_004", "COS_005"]}
    />
  );
};

export default Component;
