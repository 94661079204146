import { Container, Space, Text} from "@mantine/core";
import { useTranslation } from "react-i18next";

export const SurveyWelcome2 = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Text fw={700}>{t("welcome.confidentiality")}</Text>
      <ul>
        <li>
          <Text ta="justify">
            {t("welcome.description")}
          </Text>
        </li>
      </ul>
      <Space h="md" />
      <Text fw={700}>{t("welcome.duration")}</Text>
      <ul>
        <li>
          <Text ta="justify">
            {t("welcome.durationDescription")}
          </Text>
        </li>
      </ul>
    </Container>
  );
};
