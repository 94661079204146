import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("policyKnowledge.label")}
      {...form.getInputProps("SP_001_conocePoliticaDePrevencion")}
    >
      <Radio
        label={`${t("policyKnowledge.notReviewed")}.`}
        value="Sé que existe pero no la he revisado."
      />
      <Radio label={`${t("policyKnowledge.no")}.`} value="No." />
      <Radio label={`${t("policyKnowledge.reviewed")}.`} value="Sí, la he revisado." />
    </RadioSurvey>
  );
};

export default Component;
