import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  setHaSidoObjetoDeAcoso: (haSidoObjetoDeAcoso: boolean) => void;
};

const Component = ({ form, setHaSidoObjetoDeAcoso }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("hasBeenHarassed.label")}
      {...form.getInputProps("AD_001_haSidoObjetoDeAcoso")}
    >
      <Radio
        label={t("options.hasNotHappenedToMe")}
        value="No me ha pasado."
        onClick={() => setHaSidoObjetoDeAcoso(false)}
      />
      <Radio
        label={t("options.hasHappenedToMe")}
        value="Sí, me ha pasado."
        onClick={() => setHaSidoObjetoDeAcoso(true)}
      />
      <Radio
        label={t("options.notSure")}
        value="No estoy seguro/a."
        onClick={() => setHaSidoObjetoDeAcoso(false)}
      />
    </RadioSurvey>
  );
};

export default Component;
