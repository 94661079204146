import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("reportChannelExists.label")}
      {...form.getInputProps("SP_003_canalDeDenunciasExiste")}
    >
      <Radio label={`${t("reportChannelExists.yesAndKnowHow")}.`} value="Sí, y sé cómo acceder a él." />
      <Radio label={`${t("reportChannelExists.knowExistsButDontKnowHow")}.`} value="Sé que existe pero no sé cómo acceder a él." />
      <Radio label={`${t("no")}.`} value="No." />
    </RadioSurvey>
  );
};

export default Component;
