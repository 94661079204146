import { Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Component = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text ta="justify">{t("surveyInfoPersonalExperience.intro")}</Text>
      <Space h="md" />
      <Text ta="justify">
        {t("surveyInfoPersonalExperience.guidelinesExperience")}{" "}
        <span style={{ fontWeight: 500, textDecoration: "underline" }}>
          {t("surveyInfoPersonalExperience.guidelinesExperienceDetail")}
        </span>
        {". "}{t("surveyInfoPersonalExperience.guidelinesTimeFrame")}
      </Text>
      <Space h="md" />
      <Text ta="justify">{t("surveyInfoPersonalExperience.anonymousReminder")}</Text>
    </>
  );
};

export default Component;
