import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";
import { useTranslation } from 'react-i18next';

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <CheckboxSurvey
      label={t("declaredWitnessQuestions.label")}
      {...form.getInputProps("TD_002_013")}
    >
      {(t("declaredWitnessQuestions.options", { returnObjects: true }) as string[]).map((option, index) => (
        <Checkbox key={index} label={option} value={`td_00${index + 2}`} />
      ))}
    </CheckboxSurvey>
  );
};

export default Component;
