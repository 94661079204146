import { useTranslation } from "react-i18next";
import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();
  return (
    <MultiRadioTable
      form={form}
      label={t("concepts.label")}
      options={[`${t("yes")}.`, `${t("no")}.`]}
      questions={t("concepts.questions", { returnObjects: true }) as string[]}
      questionIds={["CPT_001", "CPT_002", "CPT_004", "CPT_003"]}
    />
  );
};

export default Component;
