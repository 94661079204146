import { Container, Title, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t("notFoundPage.title")}</Title>
      <Text>{t("notFoundPage.text")}</Text>
      <Text>{t("notFoundPage.contact")}</Text>
    </Container>
  );
};
