import { useTranslation } from "react-i18next";
import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <MultiRadioTable
      form={form}
      label={t("actionsDeclaredHarassment.label")}
      options={t("yesAndNoOptions", { returnObjects: true }) as string[]}
      questions={t("actionsDeclaredHarassment.questions", { returnObjects: true }) as string[]}
      questionIds={[
        "AAD_001",
        "AAD_002",
        "AAD_003",
        "AAD_004",
        "AAD_005",
        "AAD_006",
        "AAD_007",
        "AAD_008",
      ]}
    />
  );
};

export default Component;
