import { Container, createStyles, Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.md,
  },
}));

export const SurveyWelcome1 = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Container fluid>
      <Title>{t("hello")}</Title>
      <Space h="md" />
      <Text ta="justify">
        {t("welcome.presentation")}
      </Text>
      <Space h="md" />
      <Text>
        {t("welcome.nextSteps")}
      </Text>
      <Space h="md" />
      <Text fw={700}>{t("welcome.purpose")}</Text>
      <ul className={classes.list}>
        <li>
          <Text ta="justify">
            {t("welcome.objective")}
          </Text>
        </li>
        <li>
          <Text ta="justify">
            {t("welcome.information")}
          </Text>
        </li>
        <li>
          <Text ta="justify">
            {t("welcome.research")}
          </Text>
        </li>
      </ul>
    </Container>
  );
};
