import { MultiRadioTable } from "../../components/MultiRadioTable";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <MultiRadioTable
      form={form}
      label={t("technicalHarassmentQuestions.label")}
      options={[
        t("options.hasHappenedToMe"),
        t("options.hasNotHappenedToMe")
      ]}
      questions={t("technicalHarassmentQuestions.questions", { returnObjects: true }) as string[]}
      questionIds={[
        "AT_001_comentariosConContenidoSexual",
        "AT_002_comentariosSobreOrientacionSexual",
        "AT_003_comentariosSobreAparienciaFisica",
        "AT_004_invitarASalir",
        "AT_005_contactarPorRedesSociales",
        "AT_006_solicitarMaterialSexual",
        "AT_007_rozamientosIndebidos",
        "AT_008_tocamientosIndebidos",
        "AT_009_mirarConConnotacionSexual",
        "AT_010_invitarASalir",
        "AT_011_insinuarMejoresCondicionesACambioDeRelaciones",
        "AT_012_amenazarConSuspenderContrato"
      ]}
    />
  );
};

export default Component;
