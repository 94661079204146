import { Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Component = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text ta="justify">{t("infoPolitics.thankYou")}</Text>
      <Space h="md" />
      <Text ta="justify">
        {t("infoPolitics.continueWithQuestions")}
      </Text>
    </>
  );
};

export default Component;
