import { useTranslation } from "react-i18next";
import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey label={t("satisfied.label")} {...form.getInputProps("SAT_001")}>
      <Radio label={t("satisfied.options.totallyDissatisfied")} value="Totalmente insatisfecho/a." />
      <Radio label={t("satisfied.options.dissatisfied")} value="Insatisfecho/a." />
      <Radio label={t("satisfied.options.satisfied")} value="Satisfecho/a." />
      <Radio label={t("satisfied.options.totallySatisfied")} value="Totalmente satisfecho/a." />
    </RadioSurvey>
  );
};

export default Component;
