import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("trainingInPrevention.label")}
      {...form.getInputProps("SP_002_haSidoCapacitado")}
    >
      <Radio label={`${t("trainingInPrevention.moreThanOnce")}.`} value="Sí, más de una vez." />
      <Radio label={`${t("trainingInPrevention.once")}.`} value="Sí, una vez." />
      <Radio label={`${t("trainingInPrevention.never")}.`} value="No, nunca." />
    </RadioSurvey>
  );
};

export default Component;
