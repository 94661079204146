import { useTranslation } from "react-i18next";
import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey label={t("sexualHarassmentExperience.label")} {...form.getInputProps("AD_014")}>
      <Radio label={t("options.hasNotHappenedToMe")} value="No me ha pasado." />
      <Radio label={t("options.hasHappenedToMe")} value="Sí, me ha pasado." />
      <Radio label={t("options.notSure")} value="No estoy seguro/a." />
    </RadioSurvey>
  );
};

export default Component;
