import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("investigationProcess.label")}
      {...form.getInputProps("SP_012_conoceProcesoDeInvestigacion")}
    >
      <Radio label={`${t("investigationProcess.reviewed")}.`} value="Sí, lo he revisado." />
      <Radio label={`${t("no")}.`} value="No." />
    </RadioSurvey>
  );
};

export default Component;
