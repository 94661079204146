import { Space, Text } from "@mantine/core";
import { MultiRadioTable } from "../../components/MultiRadioTable";
import { TStaffSurveyCase } from "../../types";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  caso: TStaffSurveyCase;
  title: string;
  questionIds: string[];
};

const Component = ({ form, caso, title, questionIds }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Text size="lg">{title}</Text>
      <Space h="md" />
      <Text size="md">{caso.description}</Text>
      <Space h="md" />
      <MultiRadioTable
        form={form}
        label={caso.question_statement}
        options={t("agreementOptions", { returnObjects: true }) as string[]}
        questions={[
          caso.case_resolution_statement,
          caso.natural_heterosexuality_statement,
          caso.exacerbated_intent_statement,
          caso.victim_responsibility_statement,
          caso.overreaction_statement,
          caso.ulterior_motives_statement,
        ]}
        questionIds={questionIds}
      />
    </>
  );
};

export default Component;
