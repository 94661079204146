import { Radio } from "@mantine/core";
import { TStaffSurveyCustomField } from "../../types";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  turnos: TStaffSurveyCustomField[];
};

const Component = ({ form, turnos }: Props) => {
  const { t } = useTranslation();
  return (
    <RadioSurvey
      label={t("shift.label")}
      {...form.getInputProps("IL_002_turno")}
    >
      {turnos.map((turno) => (
        <Radio
          label={turno.description}
          value={turno.id.toString()}
          key={turno.id}
        />
      ))}
    </RadioSurvey>
  );
};

export default Component;
