import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  setParteDeRecursosHumanos: (value: boolean) => void;
};

const Component = ({ form, setParteDeRecursosHumanos }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("committeeParticipation.label")}
      {...form.getInputProps("IL_007_participacionEnComite")}
    >
      <Radio
        label={`${t("yes")}.`}
        value="Sí."
        onClick={() => setParteDeRecursosHumanos(true)}
      />
      <Radio
        label={`${t("no")}.`}
        value="No."
        onClick={() => setParteDeRecursosHumanos(false)}
      />
    </RadioSurvey>
  );
};

export default Component;
