import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();
  return (
    <CheckboxSurvey label={t("harassingGender.label")} {...form.getInputProps("PAD_001_002")}>
      <Checkbox label={`${t("gender.man")}.`} value="pad_001" />
      <Checkbox label={`${t("gender.woman")}.`} value="pad_001" />
    </CheckboxSurvey>
  );
};

export default Component;
