import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();
  return (
    <RadioSurvey
      label={t("age.select")}
      {...form.getInputProps("IP_002_edad")}
    >
      <Radio label={`60 ${t("age.years")} ${t("age.andOlder")}.`} value="60 años o más." />
      <Radio label={`50 - 59 ${t("age.years")}.`} value="50 - 59 años." />
      <Radio label={`30 - 49 ${t("age.years")}.`} value="30 - 49 años." />
      <Radio label={`18 - 29 ${t("age.years")}.`} value="18 - 29 años." />
      <Radio label={`${t("preferNotToAnswer")}.`} value="Prefiero no responder." />
    </RadioSurvey>
  );
};

export default Component;
