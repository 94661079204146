import { useTranslation } from "react-i18next";
import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <CheckboxSurvey label={t("hasBeenHarassedQuestions.label")} {...form.getInputProps("AD_002_013")}>
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_002")} value="ad_002" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_003")} value="ad_003" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_004")} value="ad_004" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_005")} value="ad_005" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_006")} value="ad_006" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_007")} value="ad_007" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_008")} value="ad_008" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_009")} value="ad_009" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_010")} value="ad_010" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_011")} value="ad_011" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_012")} value="ad_012" />
      <Checkbox label={t("hasBeenHarassedQuestions.options.ad_013")} value="ad_013" />
    </CheckboxSurvey>
  );
};

export default Component;
