import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Component = () => {
  const { t } = useTranslation();

  return (
    <Text ta="justify">
      {t("surveyInfoCaseTreatment.label")}
    </Text>
  );
};

export default Component;
