import { Checkbox, TextInput } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <CheckboxSurvey
        label={t("barriers.label")}
        {...form.getInputProps("BAD_001_010")}
      >
        {["bad_001", "bad_002", "bad_003", "bad_004", "bad_005", "bad_006", "bad_007", "bad_008", "bad_009", "otro"].map((value, index) => (
          <Checkbox
            key={value}
            label={t(`barriers.options.${index}`)}
            value={value}
          />
        ))}
      </CheckboxSurvey>
      {form.values.BAD_001_010.includes("otro") && (
        <TextInput
          mt="lg"
          width={400}
          placeholder={t("barriers.placeholder")}
          {...form.getInputProps("BAD_010")}
        />
      )}
    </>
  );
};

export default Component;
