import { Radio } from "@mantine/core";
import { TStaffSurveyCustomField } from "../../types";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  areas: TStaffSurveyCustomField[];
};

const Component = ({ form, areas }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("area.label")}
      {...form.getInputProps("IL_004_area")}
    >
      {areas?.map((area) => (
        <Radio
          label={area.description}
          value={area.id.toString()}
          key={area.id}
        />
      ))}
    </RadioSurvey>
  );
};

export default Component;
