import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("employmentDuration.select")}
      {...form.getInputProps("IL_003_tiempoTrabajando")}
    >
      <Radio label={`${t("employmentDuration.moreThan10Years")}.`} value="Hace más de 10 años." />
      <Radio label={`${t("employmentDuration.between5And10Years")}.`} value="Entre 5 y 10 años." />
      <Radio label={`${t("employmentDuration.between1And5Years")}.`} value="Entre 1 y 5 años." />
      <Radio label={`${t("employmentDuration.between3MonthsAnd1Year")}.`} value="Entre 3 meses y 1 año." />
      <Radio label={`${t("employmentDuration.lessThan3Months")}.`} value="Menos de 3 meses." />
    </RadioSurvey>
  );
};

export default Component;
