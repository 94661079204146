import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <CheckboxSurvey
      label={t("harassmentReportChannels.label")}
      {...form.getInputProps("SP_004_011_canalesDeDenuncias")}
    >
      <Checkbox label={`${t("harassmentReportChannels.immediateBoss")}.`} value="sp_004" />
      <Checkbox label={`${t("harassmentReportChannels.areaManager")}.`} value="sp_005" />
      <Checkbox label={`${t("harassmentReportChannels.workerRepresentative")}.`} value="sp_006" />
      <Checkbox label={`${t("harassmentReportChannels.webPlatform")}.`} value="sp_007" />
      <Checkbox label={`${t("harassmentReportChannels.ethicsLine")}.`} value="sp_008" />
      <Checkbox label={`${t("harassmentReportChannels.hrDepartment")}.`} value="sp_009" />
      <Checkbox label={`${t("harassmentReportChannels.harassmentCommittee")}.`} value="sp_010" />
      <Checkbox label={`${t("other")}.`} value="sp_011" />
    </CheckboxSurvey>
  );
};

export default Component;
