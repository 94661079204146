import { Container, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const SurveyFinalThankYou = () => {
  const { t } = useTranslation();

  return (
    <Container size={1200}>
      <Title>{t("final.thankYouTitle")}</Title>
      <Text>
        {t("final.thankYouMessage")}
      </Text>
    </Container>
  );
};
