import { Radio } from "@mantine/core";
import { TStaffSurveyJob } from "../../types";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  puestos: TStaffSurveyJob[];
  setIsLeader: (isLeader: boolean) => void;
};

const Component = ({ form, puestos, setIsLeader }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("jobPosition.label")}
      {...form.getInputProps("IL_006_puestoDeTrabajo")}
    >
      {puestos?.map((puesto) => (
        <Radio
          label={puesto.description}
          value={puesto.id.toString()}
          key={puesto.id}
          onClick={() => setIsLeader(puesto.is_leader)}
        />
      ))}
    </RadioSurvey>
  );
};

export default Component;
