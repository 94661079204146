import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  setHaSidoTestigoDeAcoso: (value: boolean) => void;
};

const Component = ({ form, setHaSidoTestigoDeAcoso }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("witness.label")}
      {...form.getInputProps("TD_001")}
    >
      <Radio
        label={t("options.notSure")}
        value="No estoy seguro/a."
        onClick={() => setHaSidoTestigoDeAcoso(false)}
      />
      <Radio
        label={t("witness.options.hasWitnessedHarassment")}
        value="Sí, he sido testigo de hostigamiento o acoso sexual."
        onClick={() => setHaSidoTestigoDeAcoso(true)}
      />
      <Radio
        label={t("witness.options.hasNotWitnessedHarassment")}
        value="No conozco ni he sido testigo de ningún acoso."
        onClick={() => setHaSidoTestigoDeAcoso(false)}
      />
    </RadioSurvey>
  );
};

export default Component;
