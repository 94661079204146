import { Space, Text } from "@mantine/core";
import { useTranslation } from 'react-i18next';

const Component = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text ta="justify">{t("surveyInfoCases.thanks")}</Text>
      <Space h="md" />
      <Text ta="justify">{t("surveyInfoCases.nextSection")}</Text>
    </>
  );
};

export default Component;
