import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";
import { useTranslation } from 'react-i18next';

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <CheckboxSurvey
      label={t("witnessActionsQuestions.label")}
      {...form.getInputProps("ACT_001_008")}
    >
      {(t("witnessActionsQuestions.options", { returnObjects: true }) as string[]).map((option, index) => (
        <Checkbox key={index} label={option} value={`act_00${index + 1}`} />
      ))}
    </CheckboxSurvey>
  );
};

export default Component;
