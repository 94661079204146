import { useTranslation } from "react-i18next";
import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey label={t("frequency.label")} {...form.getInputProps("FRE_001_frecuencia")}>
      <Radio label={t("frequency.options.lowFrequency")} value="Con muy baja frecuencia." />
      <Radio label={t("frequency.options.mediumLowFrequency")} value="Con poca frecuencia." />
      <Radio label={t("frequency.options.mediumFrequency")} value="Con algo de frecuencia." />
      <Radio label={t("frequency.options.highFrequency")} value="Con mucha frecuencia." />
    </RadioSurvey>
  );
};

export default Component;
