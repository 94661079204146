import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("ethnicity.select")}
      description={t("privacityRespect")}
      {...form.getInputProps("IP_004_etnia")}
    >
      <Radio label={`${t("ethnicity.asian")}.`} value="Asiático/a." />
      <Radio label={`${t("ethnicity.afrodescendant")}.`} value="Afrodescendiente." />
      <Radio label={`${t("ethnicity.white")}.`} value="Blanco/a." />
      <Radio label={`${t("ethnicity.indigenous")}.`} value="Indígena." />
      <Radio label={`${t("ethnicity.mestizo")}.`} value="Mestizo/a." />
      <Radio label={`${t("other")}.`} value="Otro." />
      <Radio label={`${t("preferNotToAnswer")}.`} value="Prefiero no responder." />
    </RadioSurvey>
  );
};

export default Component;
