import { MultiRadioTable } from "../../components/MultiRadioTable";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <MultiRadioTable
      form={form}
      label={t("organizationTrust.label")}
      options={t("agreementOptions", { returnObjects: true }) as string[]}
      questions={t("organizationTrust.questions", { returnObjects: true }) as string[]}
      questionIds={[
        "CON_001",
        "CON_002",
        "CON_003",
        "CON_004",
        "CON_005",
        "CON_006",
      ]}
    />
  );
};

export default Component;
