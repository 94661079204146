import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("perceptionLeaders.label")}
      {...form.getInputProps("PL_001_percepcionLideres")}
    >
      <Radio label={`${t("perceptionLeaders.highFrequency")}.`} value="Con mucha frecuencia." />
      <Radio label={`${t("perceptionLeaders.somewhatFrequent")}.`} value="Con algo de frecuencia." />
      <Radio label={`${t("perceptionLeaders.lowFrequency")}.`} value="Con poca frecuencia." />
      <Radio label={`${t("perceptionLeaders.veryLowFrequency")}.`} value="Con muy baja frecuencia." />
    </RadioSurvey>
  );
};

export default Component;
