import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();
  return (
    <RadioSurvey
      label={t("lgbt.label")}
      description={t("privacityRespect")}
      {...form.getInputProps("IP_003_comunidad")}
    >
      <Radio label={`${t("yes")}.`} value="Sí." />
      <Radio label={`${t("no")}.`} value="No." />
      <Radio label={`${t("preferNotToAnswer")}.`} value="Prefiero no responder." />
    </RadioSurvey>
  );
};

export default Component;
