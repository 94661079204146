import axios from "axios";
import { BackendUrls } from "./backend-urls";
import i18n from "../i18n";

export const BASE_URL =
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development"
    ? BackendUrls.DEV_API_URL
    : BackendUrls.PROD_API_URL;

export const ApiWithoutToken = axios.create({
  baseURL: BASE_URL + i18n.language,
});
