import { useTranslation } from "react-i18next";
import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();

  return (
    <CheckboxSurvey label={t("harasserProfileQuestions.label")} {...form.getInputProps("PAD_003_007")}>
      <Checkbox label={t("harasserProfileQuestions.options.contractor")} value="pad_007" />
      <Checkbox label={t("harasserProfileQuestions.options.client")} value="pad_006" />
      <Checkbox label={t("harasserProfileQuestions.options.subordinate")} value="pad_005" />
      <Checkbox label={t("harasserProfileQuestions.options.supervisor")} value="pad_004" />
      <Checkbox label={t("harasserProfileQuestions.options.colleague")} value="pad_003" />
    </CheckboxSurvey>
  );
};

export default Component;
