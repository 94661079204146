import { useTranslation } from "react-i18next";
import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  const { t } = useTranslation();
  return (
    <MultiRadioTable
      form={form}
      label={t("witnessQuestions.label")}
      options={t("witnessQuestions.options", { returnObjects: true }) as string[]}
      questions={t("witnessQuestions.questions", { returnObjects: true }) as string[]}
      questionIds={[
        "TT_001",
        "TT_002",
        "TT_003",
        "TT_004",
        "TT_005",
        "TT_006",
        "TT_007",
        "TT_008",
        "TT_009",
        "TT_010",
        "TT_011",
        "TT_012",
      ]}
    />
  );
};

export default Component;
