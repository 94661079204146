import { Radio } from "@mantine/core";
import { TStaffSurveyData } from "../../types";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  staff_survey: TStaffSurveyData;
};

const Component = ({ form, staff_survey }: Props) => {
  const { t } = useTranslation();
  return (
    <RadioSurvey
      label={t("gender.select")}
      {...form.getInputProps("IP_001_genero")}
    >
      <Radio label={`${t("gender.male")}.`} value="Masculino." />
      <Radio label={`${t("gender.female")}.`} value="Femenino." />
      {staff_survey.staff_survey.flag_001 && (
        <Radio
          label={`${t("gender.nonBinary")} (${t("gender.nonBinaryDescription")}).`}
          value="No binario."
        />
      )}
    </RadioSurvey>
  );
};

export default Component;
