import { Radio } from "@mantine/core";
import { TStaffSurveyCustomField } from "../../types";
import { RadioSurvey } from "../../components";
import { useTranslation } from "react-i18next";

type Props = {
  form: any;
  centros: TStaffSurveyCustomField[];
};

const Component = ({ form, centros }: Props) => {
  const { t } = useTranslation();

  return (
    <RadioSurvey
      label={t("workplace.select")}
      {...form.getInputProps("IL_009_centroDeTrabajo")}
    >
      {centros.map((centro) => (
        <Radio
          label={centro.description}
          value={centro.id.toString()}
          key={centro.id}
        />
      ))}
    </RadioSurvey>
  );
};

export default Component;
