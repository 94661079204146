import {
  Kbd,
  // Container,
  Space,
  Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

export const SurveyAcknowledgement = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text ta="justify">{t("acknowledgment.participate")}</Text>    
      <Text ta="justify">
        {t("acknowledgment.navigate")}{" "}
        <Kbd>Enter</Kbd> {t("acknowledgment.nextScreen")}
      </Text>
      <Space h="md" />
      <Text>{t("acknowledgment.letsBegin")}</Text>
      <Space h="md" />

      <Text ta="justify">
        {t("acknowledgment.intro")}
      </Text>
    </>
  );
};
